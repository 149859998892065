import styled from 'styled-components';
import { InputBase, TextField, TextareaAutosize, withStyles } from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';

export const DealTitle = styled.h5`
  color: #626263;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

export const DealFormLabel = styled.h6`
  color: #626263;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  margin: 0;
`;

export const TextArea = styled( TextareaAutosize )`
  border: 1px solid #ECECEC;
  color:  #626263;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  width: 100%;

  padding: 8px 14px;

  height: 40px;

  &:focus {
    border: 1px solid #ECECEC;
    outline: none;
    box-shadow: none;
  }
`;

export const DealDatePicker = withStyles( {
	root: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#ECECEC',
			},
		},
		'& .MuiOutlinedInput-input': {
			color: '#626263',
		},
	},
} )( DatePicker );

export const CaseInput = withStyles( {
	root: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#ECECEC !important',
				
			},
		},
		'& .MuiOutlinedInput-input': {
			color: '#626263',
		},
		'& .Mui-disabled': {
			backgroundColor : '#ECECEC',
			color           : '#626263',
			'& fieldset'    : {
				borderColor: '#ECECEC',
			},
		},
	},
} )( TextField );

export const BootstrapInput = withStyles( ( theme ) => ( {
	input: {
		borderRadius    : 4,
		backgroundColor : theme.palette.background.paper,
		border          : '1px solid #ECECEC',
		fontSize        : 16,
		padding         : '8.5px 14px 8.5px 14px',
		transition      : theme.transitions.create( ['border-color'] ),
		height          : '21px',
		color           : '#626263',

		'&:focus': {
			borderRadius    : 4,
			borderColor     : '#ECECEC',
			backgroundColor : theme.palette.background.paper,
		},
	},
} ) )( InputBase );
