import styled from 'styled-components';

export const SidebarContainer = styled.div`
    width: 70px;
    background-color: #f0f2f5;
    border-right: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    height: 100%;
`;

export const SidebarIcon = styled.div`
    color: #54656f;
    width: 24px;
    height: 24px;
    position: relative;
`;

export const SidebarButton = styled.button`
    width: 100%;
    padding: 15px 0;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    ${props => props.active && `
        background-color: #e9edef;

        ${SidebarIcon} {
            color: #00a884;
        }
    `}

    &:hover {
        background-color: #e9edef;
    }
`;

export const UnreadBadge = styled.span`
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #00a884;
    color: white;
    border-radius: 12px;
    padding: 2px 6px;
    font-size: 12px;
    min-width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
