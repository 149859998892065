export const formatDate = ( date ) => {
	const newDate = new Date( date );
	const today = new Date();
	const yesterday = new Date( today );
	yesterday.setDate( yesterday.getDate() - 1 );

	if ( newDate.toDateString() === today.toDateString() ) {
		return 'Hoje';
	} else if ( newDate.toDateString() === yesterday.toDateString() ) {
		return 'Ontem';
	}

	return newDate.toLocaleDateString( 'pt-BR', {
		day   : '2-digit',
		month : '2-digit',
		year  : 'numeric'
	} );
};

export const formatMessageTime = ( date ) => {
	const messageDate = new Date( date );
	const now = new Date();
	const yesterday = new Date( now );
	yesterday.setDate( yesterday.getDate() - 1 );
	if ( !messageDate ) {
		return '';
	}

	if ( messageDate.toDateString() === now.toDateString() ) {
		return messageDate.toLocaleTimeString( [], {
			hour     : '2-digit',
			minute   : '2-digit',
			hour12   : false,
			timeZone : 'UTC'
		} );
	}

	if ( messageDate.toDateString() === yesterday.toDateString() ) {
		return 'Ontem';
	}

	if ( messageDate.getFullYear() === now.getFullYear() ) {
		return messageDate.toLocaleDateString( 'pt-BR', {
			day   : '2-digit',
			month : '2-digit'
		} );
	}

	return messageDate.toLocaleDateString( 'pt-BR', {
		day   : '2-digit',
		month : '2-digit',
		year  : 'numeric'
	} );
};
