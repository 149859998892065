import styled from 'styled-components';
import DatePicker from 'react-date-picker';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    font-weight: 400;
    color: #808080;
  }
`;

export const StyledDatePicker = styled( DatePicker )`
  .react-date-picker__inputGroup{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .react-date-picker__wrapper{
    border-radius: 4px;
  }
  width: 20%;
`;
