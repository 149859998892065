import { create } from 'zustand';

export const useWhatsappChatStore = create( ( set ) => ( {
	states: {
		whatsappChat: {
			isOpen         : false,
			selectedNumber : null,
		},
	},
	actions: {

		// Add new actions for WhatsApp chat
		openWhatsappChat: ( phoneNumber ) => {
			set( ( store ) => ( {
				states: {
					...store.states,
					whatsappChat: {
						isOpen         : !store.states.whatsappChat.isOpen,
						selectedNumber : phoneNumber,
					},
				},
			} ) );
		},

		closeWhatsappChat: () => {
			set( ( store ) => ( {
				states: {
					...store.states,
					whatsappChat: {
						isOpen         : false,
						selectedNumber : null,
					},
				},
			} ) );
		},
	},
} ) );
