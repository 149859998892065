import styled from 'styled-components';

export const AuthContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #f0f2f5;
	padding: 20px;
`;

export const QRCodeContainer = styled.div`
	background: white;
	padding: 30px;
	border-radius: 8px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.1);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
`;

export const StatusMessage = styled.p`
	font-size: 16px;
	color: #54656f;
	margin-bottom: 20px;
	text-align: center;
`;
