import React from 'react';
import { ChatContainer, ChatLayout, ModalOverlay } from 'newComponents/ChatWhatsapp/styles';
import { Sidebar } from '../Sidebar';
import { ConnectionStatus } from 'newComponents/ChatWhatsapp/ConnectionStatus';
import PropTypes from 'prop-types';
// import { Button } from '@mui/material';


export const ModalStatusSession = ( { connectionStatus, qrCode, activeTab, setActiveTab, handleUpdateSession } ) => {
	return (
		<ModalOverlay>
			<ChatContainer>
				<ChatLayout>
					{connectionStatus.isAuthenticated && 
				<Sidebar
					activeTab={activeTab}
					setActiveTab={setActiveTab}
				/>
					}
					<div style={{ flex: 1 }}>
						<ConnectionStatus
							status={connectionStatus}
							isDisconnected={connectionStatus.isDisconnected}
							qrCode={qrCode}
							handleUpdateSession={handleUpdateSession}
						/>
					</div>
				</ChatLayout>
			</ChatContainer>
		</ModalOverlay>
	);
};

ModalStatusSession.propTypes = {
	connectionStatus: PropTypes.shape( {
		isDisconnected: PropTypes.bool.isRequired,
		// Add other connection status properties as needed
	} ).isRequired,
	qrCode              : PropTypes.string,
	activeTab           : PropTypes.string.isRequired,
	setActiveTab        : PropTypes.func.isRequired,
	handleUpdateSession : PropTypes.func.isRequired
};

ModalStatusSession.defaultProps = {
	qrCode: null
};
