import React from 'react';
// import { Button } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import Button from 'newComponents/Button';

const ConnectionStatus = ( { status, qrCode, handleUpdateSession } ) => {
	return (
		<div style={{
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'center',
			height         : '100%',
			padding        : '20px',
			textAlign      : 'center',
			color          : '#333'
		}}>
			{ !status.isAuthenticated && (
				<>
					<h2>WhatsApp não autenticado</h2>
					{qrCode ? (
						<>
							<p>Por favor, escaneie o QR Code com seu WhatsApp para conectar:</p>
							<div style={{ marginTop: '20px' }}>
								<img 
									src={qrCode} 
									alt="QR Code"
									style={{
										width   : '256px',
										height  : '256px',
										display : 'block',
										margin  : 'auto'
									}}
								/>
							</div>
							<p style={{ marginTop: '20px', fontSize: '14px', color: '#666' }}>
								Aguardando leitura do QR Code...
							</p>
							<Button onClick={handleUpdateSession}><Refresh /> Atualizar</Button>
						</>
					) : (
						<>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
								<p>Aguardando geração do QR Code...</p>
								<div className="loading-spinner"></div>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export { ConnectionStatus };
