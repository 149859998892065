import { io } from 'socket.io-client';

const SOCKET_URL = 'https://bailey-api-dev-263680973121.us-central1.run.app';
// const SOCKET_URL = 'https://bailey-api-263680973121.us-central1.run.app';

// const SOCKET_URL = 'http://localhost:3001';

let socket = null;

export const socketEvents = {
	connect: () => {
		if ( !socket ) {

			const token = localStorage.getItem( 'af-token' );

			socket = io( SOCKET_URL, {
				extraHeaders: {
				  Authorization: `Bearer ${token}`
				} } );
		}
		return socket;
	},

	onQRCode: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'qrcode.updated', callback );
		return () => socket.off( 'qrcode.updated', callback );
	},

	onAuthenticated: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'connection.update', callback );
		return () => socket.off( 'connection.update', callback );
	},

	//chats.update
	onChatUpdate: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'chats.upsert', ( chat ) => {
			console.log( 'onChatUpdate: ', chat );
			callback( chat );
		} );
		return () => socket.off( 'chats.upsert', callback );
	},
	onChatUpdate2: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'chats.update', ( chat ) => {
			console.log( 'onChatUpdate: ', chat );
			callback( chat );
		} );
		return () => socket.off( 'chats.update', callback );
	},

	onUnreadUpdate: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'messages.upsert', ( message ) => {
			console.log( 'onUnreadUpdate: ', message );
			callback( message );
		} );
		return () => socket.off( 'messages.upsert', callback );
	},

	onMessage: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'messages.upsert', ( message ) => {
			console.log( 'onMessage: ', message );
			callback( message );
		} );
		return () => socket.off( 'messages.upsert', callback );
	},

	onMessageStatus: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'messages.upsert', ( message ) => {
			console.log( 'onMessageStatus: ', message );
			callback( message );
		} );
		return () => socket.off( 'messages.upsert', callback );
	},

	onAuthFailure: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'connection.update', callback );
		return () => socket.off( 'connection.update', callback );
	},

	onSentMessage: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'sent-messages.upsert', callback );
		return () => socket.off( 'sent-messages.upsert', callback );
	},


	disconnect: () => {
		if ( socket ) {
			socket.disconnect();
			socket = null;
		}
	},
	offMessage: ( callback ) => {
		const socket = socketEvents.connect();
		socket.off( 'message', callback );
	},

	offMessageStatus: ( callback ) => {
		const socket = socketEvents.connect();
		socket.off( 'message-status', callback );
	},

	offUnreadUpdate: ( callback ) => {
		const socket = socketEvents.connect();
		socket.off( 'unread_update', callback );
	},

	onConnectionStatus: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'connection_status', callback );
		return () => socket.off( 'connection_status', callback );
	},

	onDisconnected: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'disconnected', callback );
		return () => socket.off( 'disconnected', callback );
	},

	onReady: ( callback ) => {
		const socket = socketEvents.connect();
		socket.on( 'ready', callback );
		return () => socket.off( 'ready', callback );
	},
};
