import { InputLabel } from '@material-ui/core';
import { DatePicker } from 'newComponents/DatePicker';
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #fff;

  width: 50vw;
  height: 40vh;
`;

export const DatePickerCampaign = styled( DatePicker )`
  width: 100%;
  .react-date-picker__inputGroup {
    display: flex;
    justify-content: center;
  }

  .react-date-picker__wrapper {
    height: 32px;
    border-radius: 3px;
    border: 1px solid #808080;
  }
`;

export const CampaignDateLabel = styled( InputLabel )`
  color: #808080;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const InputCampaign = styled.input`
  width: 100%;
  height: 40px;

  padding: 8px;
  border: 1px solid ${props => ( props.error ? '#EB5A46' : '#DEDEDE' )};
  border-radius: 4px;
`;

export const InputQueue = styled.input`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px;

  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${props => ( props.error ? '#EB5A46' : '#DEDEDE' )};
`;

export const DialerQueueCasesFiltered = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;

  .dialer-queue-cases-title {
    color: #808080;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .dialer-queue-cases-quantity {
    color: #505051;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const DialerQueueDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 12px;

  .dialer-queue-title {
    color: #626263;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 169.25%;
  }

  .dialer-quantity-title {
    color: #5a5a5b;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 169.25%;
    /* margin-top: 16px; */
  }

  .dialer-queue-name-container {
    display: flex;
    flex-direction: column;

    width: 100%;

    gap: 8px;
  }

  .dialer-queue-user-list {
    display: flex;
    flex-direction: column;

    overflow: auto;

    &::-webkit-scrollbar-track {
      border-radius: 14px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 10px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 14px;
      background-color: #c4c4c4;
    }

    max-height: 24vh;
    margin-left: 8px;

    width: 100%;
    gap: 4px;
  }

  .dialer-queue-user-list-new-team {
    display: flex;
    flex-direction: column;

    overflow: auto;

    &::-webkit-scrollbar-track {
      border-radius: 14px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 10px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 14px;
      background-color: #c4c4c4;
    }

    max-height: 24vh;

    width: 100%;
  }
`;

export const DialerQueueSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  .search-span {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    color: #5a5a5b;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 169.25%; /* 27.08px */
  }

  .dialer-row {
  }
`;

export const CreateDialerFinalStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 24px;

  width: 100%;

  gap: 24px;

  .dialer-campaign-feedback-subtitle-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;

    text-align: center;

    .container-feedback-text {
      font-family: Roboto;
      font-size: 16px;
      color: #000;
      font-weight: 700;
    }
  }

  .dialer-creation-feedback-title-success {
    color: #1781aa;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  .dialer-creation-feedback-title-error {
    color: #eb5a46;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  .dialer-creation-feedback-subtitle {
    color: #626263;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
`;

export const TextInputError = styled.span`
  color: #eb5a46;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

export const TextUserList = styled.span`
  color: #626263;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

export const NegotiatorCard = styled.div`
  padding: 4px 0px;
`;

export const SelectQueueDistributionRow = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid "#DEDEDE";
  align-items: center;
  align-self: stretch;

  .MuiSelect-select {
    padding: 8px;
    color: #626263;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
  }
`;
