import React, { useState, useEffect } from 'react';
import {
	ChatContainer,
	ModalOverlay,
	ChatLayout,
} from './styles';
import { pipego } from 'api/pipego';
import { socketEvents } from 'lib/socket';
import { useWhatsappChatStore } from 'store/whatsappChat/useWhatsappChatStore';
import { ChatList } from './components/ChatList';
import { ChatArea } from './components/ChatArea';
import { Settings } from './components/Settings';
import { Sidebar } from './components/Sidebar';
import { ModalStatusSession } from './components/ModalStatusSession';
import { useForm } from 'react-hook-form';
import { useMessage } from './context/MessageContext';
import useWebSocket from './context/useWebSocket.hook';

const ChatWhatsappContent = () => {
	const { whatsappChat } = useWhatsappChatStore( ( state ) => state.states );
	const form = useForm();
	const {
		checkWhatsAppStatus,
		setQrCode,
		handleChatClick,
		totalUnread,
		totalUnreadPendingChat,
		connectionStatus,
		qrCode,
		activeTab,
		setActiveTab,
		setConnectionStatus,
		selectedChat,
		conversations,
		onReceivedMessage
	} = useMessage( form );
	useWebSocket( onReceivedMessage, selectedChat );

	const isOpen = whatsappChat.isOpen;
	const [ showUnreadOnly, setShowUnreadOnly ] = useState( false );
	const [ searchQuery, setSearchQuery ] = useState( '' );

	useEffect( () => {

		checkWhatsAppStatus();

		const unsubscribeQR = socketEvents.onQRCode( ( qrData ) => {
			console.log( 'Novo QR Code recebido via socket:', qrData?.data?.data?.qr );
			setQrCode( qrData?.data?.data?.qr || null );
		} );

		const unsubscribeAuth = socketEvents.onAuthenticated( () => {
			console.log( 'WhatsApp autenticado via socket' );
			setConnectionStatus( prev => ( {
				...prev,
				isAuthenticated: true
			} ) );
			setQrCode( null );
		} );

		// const unsubscribeAuthFailure = socketEvents.onAuthFailure( () => {
		// 	console.log( 'Falha na autenticação do WhatsApp' );
		// 	setConnectionStatus( prev => ( {
		// 		...prev,
		// 		isAuthenticated: false
		// 	} ) );
		// 	checkWhatsAppStatus();
		// } );

		return () => {
			unsubscribeQR();
			unsubscribeAuth();
			// unsubscribeAuthFailure();
		};
	}, [] );

	// useEffect( () => {
	// 	//Event - receber QR Code
	// 	console.log( 'useEffect.onQRCode: ', connectionStatus );
	// 	socketEvents.onQRCode( ( qrData ) => {
	// 		setQrCode( qrData.data.data.qr || null );
	// 	} );

	// }, [ connectionStatus ] );

	if ( !isOpen ) return null;

	if ( !connectionStatus.isAuthenticated ) {
		return (
			<ModalStatusSession
				connectionStatus={connectionStatus}
				qrCode={qrCode}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				handleUpdateSession={checkWhatsAppStatus}
			/>
		);
	}

	return (
		<ModalOverlay>
			<ChatContainer>
				<ChatLayout>
					<Sidebar
						totalUnread={totalUnread}
						totalUnreadPendingChat={totalUnreadPendingChat}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>
					{activeTab === 'settings' && (
						<Settings
							onBack={() => setActiveTab( 'chats' )}
							onLogout={async () => {
								try {
									await pipego.logoutWhatsApp();
									checkWhatsAppStatus();
								} catch ( error ) {
									console.error( 'Error logging out:', error );
								}
							}}
							qrCode={qrCode}
							isAuthenticated={connectionStatus.isAuthenticated}
							isLoading={!connectionStatus.hasQR && !connectionStatus.isAuthenticated}
						/>
					)}
					{activeTab === 'chats' && (
						<ChatList
							conversations={conversations.filter( conversation => conversation?.userId )}
							searchQuery={searchQuery}
							showUnreadOnly={showUnreadOnly}
							setShowUnreadOnly={setShowUnreadOnly}
							setSearchQuery={setSearchQuery}
							handleChatClick={handleChatClick}
							totalUnread={totalUnread}
						/>
					)}
					{activeTab === 'pending' && (
						<ChatList
							conversations={conversations.filter( conversation => !conversation?.userId )}
							searchQuery={searchQuery}
							showUnreadOnly={showUnreadOnly}
							setShowUnreadOnly={setShowUnreadOnly}
							setSearchQuery={setSearchQuery}
							handleChatClick={handleChatClick}
						/>
					)}

					{selectedChat && (
						<ChatArea
							useForm={form}
						/>
					)}
				</ChatLayout>
			</ChatContainer>
		</ModalOverlay>
	);
};

const ChatWhatsapp = () => {
	return (
		<ChatWhatsappContent />
	);
};

export { ChatWhatsapp };
