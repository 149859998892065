import React from 'react';
import PropTypes from 'prop-types';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import * as S from './styles';


const ChatListHeader = ( {
	showUnreadOnly,
	setShowUnreadOnly,
	searchQuery,
	setSearchQuery
} ) => {
	return (
		<S.HeaderContainer>
			<S.HeaderTop>
				<S.Title>
          Conversas
				</S.Title>
				<S.FilterButton
					onClick={() => setShowUnreadOnly( !showUnreadOnly )}
					active={showUnreadOnly}
				>
					<FilterListIcon style={{ color: showUnreadOnly ? 'white' : '#aebac1' }} />
				</S.FilterButton>
			</S.HeaderTop>

			<S.SearchContainer>
				<SearchIcon style={{
					position  : 'absolute',
					left      : '12px',
					top       : '50%',
					transform : 'translateY(-50%)',
					color     : '#aebac1'
				}} />
				<S.SearchInput
					type="text"
					placeholder="Search chats"
					value={searchQuery}
					onChange={( e ) => setSearchQuery( e.target.value )}
				/>
			</S.SearchContainer>
		</S.HeaderContainer>
	);
};

ChatListHeader.propTypes = {
	showUnreadOnly    : PropTypes.bool.isRequired,
	setShowUnreadOnly : PropTypes.func.isRequired,
	searchQuery       : PropTypes.string.isRequired,
	setSearchQuery    : PropTypes.func.isRequired,
};

export { ChatListHeader };
