import styled from 'styled-components';

export const SettingsContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: #fff;
`;

export const SettingsHeader = styled.div`
	background-color: #008069;
	color: white;
	padding: 16px;
	display: flex;
	align-items: center;
	gap: 16px;
`;

export const BackButton = styled.button`
	background: none;
	border: none;
	color: white;
	cursor: pointer;
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;

	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}
`;

export const SettingsTitle = styled.h2`
	margin: 0;
	font-size: 20px;
	font-weight: 500;
`;

export const SettingsContent = styled.div`
	flex: 1;
	padding: 20px;
	background-color: #f0f2f5;
`;

export const SettingsOption = styled.button`
	width: 100%;
	padding: 16px;
	display: flex;
	align-items: center;
	gap: 16px;
	background: white;
	border: none;
	cursor: pointer;
	color: #54656f;
	border-radius: 8px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.08);

	svg {
		width: 24px;
		height: 24px;
		color: #54656f;
	}

	span {
		font-size: 16px;
	}

	&:hover {
		background-color: #f5f6f6;
	}
`;
