import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Button from 'newComponents/Button';
import React from 'react';

const DialogComponent = ( { confirmation, closeFunction, callback, mutateObject, title, subText, zIndex, children, loading } ) => {

	return (
		<Dialog
			open={confirmation}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			style={{ zIndex: zIndex || 9999 }}
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{subText}
				</DialogContentText>
				{children}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => closeFunction()} variant='Secondary'>
                            Voltar
				</Button>
				<Button onClick={() => { mutateObject ? callback( mutateObject ) : callback(); }} loading={loading}>
                            Confirmar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogComponent;
