import React from 'react';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import * as S from './styles';
import { AccountCircleRounded } from '@mui/icons-material';

export function MenuUser( { handlers, openMenuUser, auth, userSignOut } ) {
	const anchorRefUser = React.useRef( null );
	const classes = S.useStyles();

	return (
		<div>
			<Button
				className={classes.button}
				ref={anchorRefUser}
				aria-controls={openMenuUser ? 'menu-list-user' : undefined}
				aria-haspopup="true"
				onClick={() => handlers.setOpenMenuUser( !openMenuUser )}
			>
				<span className={'d-flex d-lg-none'}><AccountCircleRounded fontSize="small" /></span>
				<span className={'d-none d-lg-flex'}>{auth.userLogin}</span>
			</Button>
			<Popper open={openMenuUser} anchorEl={anchorRefUser.current} role={undefined} transition disablePortal>
				{( { TransitionProps, placement } ) => (
					<Grow
						{...TransitionProps}
						className={classes.grow}
						style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
						<Paper>
							<ClickAwayListener onClickAway={() => handlers.setOpenMenuUser( false )}>
								<MenuList className={classes.menuList} id="menu-list-user" autoFocusItem>
									<MenuItem className={classes.menuItem}> <NavLink className={classes.link} to="/app/profile">Perfil</NavLink></MenuItem>
									<MenuItem className={classes.menuItem}> <NavLink className={classes.link} to="/app/cadastro/digital-certificate"> Certificados </NavLink></MenuItem>
									<MenuItem onClick={() => userSignOut()} className={classes.menuItem}>Sair</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
}
