import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { ModalBody, Overlay } from './styles';

function Modal( { visible, onClose, titleHeader, children, hidden } ) {

	if ( !visible ) {
		return null;
	}

	return (
		<Overlay >
			<ModalBody>
				<header>
					<strong>{titleHeader}</strong>
					<button type='button' onClick={onClose} hidden={hidden}>
						<CloseIcon fontSize='small' />
					</button>
				</header>
				{children}
			</ModalBody>
		</Overlay>
	);
}

Modal.propTypes = {
	visible     : PropTypes.bool,
	onClose     : PropTypes.func,
	titleHeader : PropTypes.string,
	children    : PropTypes.node,
	hidden      : PropTypes.bool
};

Modal.defaultProps = {
	visible     : false,
	onClose     : () => {},
	titleHeader : '',
	children    : null,
	hidden      : false
};

export default Modal;
