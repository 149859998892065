import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { pipego } from 'api/pipego';
import { useNotifyAlert } from 'context/NotifyAlert';

const MessageContext = createContext( {} );

export const MessageProvider = ( { children } ) => {
	const [ messages, setMessages ] = useState( [] );
	const [ messagesCursor, setMessagesCursor ] = useState( null );
	const [ activeTab, setActiveTab ] = useState( 'settings' );
	const [ selectedChat, setSelectedChat ] = useState( null );
	const [ chatListCursor, setChatListCursor ] = useState( null );
	const [ conversations, setConversations ] = useState( [] );
	const [ connectionStatus, setConnectionStatus ] = useState( {
		isAuthenticated : false,
		hasQR           : false,
		isDisconnected  : false
	} );

	const [ qrCode, setQrCode ] = useState( null );
	const { setShowNotify } = useNotifyAlert();

	const selectedChatRef = useRef( selectedChat );

	useEffect( () => {
		selectedChatRef.current = selectedChat;
	}, [ selectedChat ] );

	// Calculate total unread messages
	const totalUnreadPendingChat = conversations?.filter( conv => !conv?.userId ).length;
	const totalUnread = conversations?.filter( conv => conv?.userId ).reduce( ( sum, conv ) => sum + ( conv?.unreadCount || 0 ), 0 );

	async function fetchMessages() {

		try {
			if ( !selectedChat?.id || !messagesCursor ) return;

			const response = await pipego.getMessageHistory( selectedChat.id, messagesCursor );

			const formattedMessages = response?.messages?.map( ( msg ) => {
				return {
					id        : msg.id,
					content   : msg.content,
					createdAt : msg.createdAt,
					type      : msg.fromMe ? 'sent' : 'received',
					status    : msg.status
				};
			} );

			setMessages( ( prev ) => [ ...formattedMessages, ...prev ] );
			setMessagesCursor( response.cursor );
		} catch ( error ) {
			console.error( 'Error fetching messages:', error );
		}
	}

	async function handleSendMessage( e, form, userEmail ) {
		form.reset( { 'message': '' } );

		if ( !e?.message?.trim() || !selectedChat?.id ) {
			return;
		}

		try {
			const messageWithEmail = userEmail ? `*${userEmail.split( '@' )[0]}*:\n${e.message}` : e.message;
			const response = await pipego.sendMessage( selectedChat?.id, { text: messageWithEmail, mentions: [] } );

			const messageToSend = {
				id        : response?.key?.id,
				content   : messageWithEmail,
				createdAt : new Date(),
				type      : 'sent',
				status    : 0
			};

			setMessages( prev => [ ...prev.filter( msg => msg.id !== messageToSend.id ), messageToSend ] );
		} catch ( error ) {
			console.error( 'Erro ao enviar mensagem:', error );
		}
	}

	async function loadChats() {
		try {
			//if ( !connectionStatus.isAuthenticated ) return;
			const response = await pipego.getOperatorChats( chatListCursor );

			setChatListCursor( response.cursor ?? null );
			setConversations( ( prev ) => [ ...prev, ...response.chatData ] );
			setQrCode( null );
		} catch ( error ) {
			if ( error.response?.data?.qrCode ) {
				setQrCode( error.response.data.qrCode );
			}
			console.error( 'Erro ao carregar conversas:', error );
		}
	}

	async function assignChat( jid, userId, userEmail, caseId ) {
		try {
			if ( caseId?.length < 6 ) {
				return setShowNotify( {
					status : 'error',
					text   : 'Digite um id de caso válido ( pelo menos 6 dígitos )'
				} );
			}
			const response = await pipego.updateOperator( jid, userId, userEmail, caseId );

			if ( response.status === 200 ) {
				return setShowNotify( {
					status : 'success',
					text   : 'Atendimento atribuído com sucesso!'
				} );
			}
		} catch ( error ) {
			console.log( error );
			return setShowNotify( {
				status : 'error',
				text   : 'Algo deu errado.'
			} );
		}
	}

	async function closeChat( isCaseAssigned, jid ) {
		try {
			if ( !isCaseAssigned ) {
				setShowNotify( {
					status : 'error',
					text   : 'Falha ao finalizar atendimento. Não foi atribuído um caso ao chat'
				} );
				return;
			}

			const response = await pipego.closeChatService( jid );

			if ( response.status === 200 ) {
				return setShowNotify( {
					status : 'success',
					text   : 'Atendimento finalizado com sucesso!'
				} );
			}

			return setShowNotify( {
				status : 'error',
				text   : 'Erro interno.'
			} );
		} catch ( error ) {
			console.log( error );
			return setShowNotify( {
				status : 'error',
				text   : 'Algo deu errado.'
			} );
		}
	}

	function statusHandler( status ) {
		setMessages( prev => prev.map( msg =>
			msg.id === status.messageId ? { ...msg, status: status.ack } : msg
		) );
	}

	async function handleChatClick( conversation ) {
		const messageHistory = await pipego.getMessageHistory( conversation?.id );
		try {
			if ( messageHistory ) {
				const formattedMessages = messageHistory?.messages?.map( msg => ( {
					id        : msg.id,
					content   : msg.content,
					createdAt : msg.createdAt,
					type      : msg.fromMe ? 'sent' : 'received',
					status    : msg.status
				} ) );
				setMessages( formattedMessages );
				setMessagesCursor( messageHistory.cursor );
			}
		} catch ( error ) {
			console.log( 'sem historico' );
			setMessages( [] );
		}

		setSelectedChat( {
			...conversation,
			operatorEmail: messageHistory?.userEmail || 'Não atribuído' // Add operator information
		} );
	}

	async function checkWhatsAppStatus() {
		try {
			const status = await pipego.getWhatsAppStatus();
			// Atualiza o status de autenticação
			setConnectionStatus( prev => ( {
				...prev,
				isAuthenticated: status.isAuthenticated
			} ) );

			if ( !status.isAuthenticated ) {
				const qrResponse = await pipego.createSession();
				setQrCode( qrResponse?.qr || null );
			}
		} catch ( error ) {
			console.error( 'Erro ao verificar status do WhatsApp:', error );
			setConnectionStatus( prev => ( {
				...prev,
				isAuthenticated: false
			} ) );
		}
	}

	function onReceivedMessage( message ) {
		const messageHistory = message?.data?.data;

		const formattedMessages = messageHistory?.messages?.filter( msg => !msg.fromMe && msg.remoteJid === selectedChatRef.current?.id ).map( msg => ( {
			id        : msg.id,
			content   : msg.content,
			createdAt : msg.createdAt,
			type      : msg.fromMe ? 'sent' : 'received',
			status    : msg.status
		} ) );

		if ( formattedMessages ) {
			setMessages( prev => [ ...prev, ...formattedMessages ] );
		}
	}

	return (
		<MessageContext.Provider
			value={{
				messages,
				activeTab,
				selectedChat,
				conversations,
				connectionStatus,
				qrCode,
				totalUnread,
				totalUnreadPendingChat,
				setMessages,
				setActiveTab,
				setSelectedChat,
				setConversations,
				setConnectionStatus,
				setQrCode,
				fetchMessages,
				handleSendMessage,
				loadChats,
				statusHandler,
				handleChatClick,
				checkWhatsAppStatus,
				onReceivedMessage,
				messagesCursor,
				chatListCursor,
				closeChat,
				assignChat,
			}}
		>
			{children}
		</MessageContext.Provider>
	);
};

export const useMessage = () => {
	const context = useContext( MessageContext );
	if ( !context ) {
		throw new Error( 'useMessage must be used within a MessageProvider' );
	}
	return context;
};
