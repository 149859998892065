import React, { useEffect, useRef, useState } from 'react';
import { AccountCircle, Send } from '@material-ui/icons';
import {
	ChatHeader,
	ChatHeaderInfo,
	ChatMessages,
	Message,
	MessageContent,
	MessageTimestamp,
	ChatInputForm,
	ChatInput,
	SendButton,
	DateSeparator,
	CloseButton,
	ButtonSelectOperator,
	LoadMoreButton,
	LoadingMessageContainer,
} from './styles';
import { formatDate } from '../../utils/dateFormatters';
import { ArrowDropDown } from '@mui/icons-material';
import Modal from 'newComponents/Modal';
import { useChatArea } from './hooks/useChatArea.hooks';
import SelectSearch from 'newComponents/SelectSearch';
import { BootstrapInput } from 'app/routes/CaseDetails/CaseCard/NegotiationTab/styles';
import { useQuery } from 'react-query';
import { useMessage } from '../../context/MessageContext';
import { useWhatsappChatStore } from 'store/whatsappChat/useWhatsappChatStore';
import { ChatActions } from '../ChatActions';
import DialogComponent from 'newComponents/DialogComponent';
import { useSelector } from 'react-redux';

const ChatArea = ( {
	useForm,
} ) => {
	const { fetchMessages, messagesCursor, messages, handleSendMessage, closeChat, assignChat } = useMessage();
	const userEmail = useSelector( state => state.auth.userLogin );
	const { closeWhatsappChat } = useWhatsappChatStore( ( state ) => state.actions );
	const [ isLoading, setIsLoading ] = useState( false );
	const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

	const [ loadingCloseService, setLoadingCloseService ] = useState( false );
	const [ openDialog, setOpenDialog ] = useState( false );
	const [ chatIdInput, setChatIdInput ] = useState( '' );
	const { isAdmin, userId, handleOpenModal, handleCloseModal, visible, handleFetchOperators, handleUpdateOperator, selectedChat } = useChatArea();

	const { data: operators } = useQuery( {
		queryKey : [ 'operators' ],
		queryFn  : () => handleFetchOperators()
	} );

	const chatRef = useRef( null );
	const isInitialLoad = useRef(true);
	const isCaseAssigned = !!selectedChat?.caseId;
	const isConversationEnded = !!selectedChat?.closedAt;

	const formatMessageContent = (text) => {
		const parts = text.split(/(\*[^*]+\*)/);
		return parts.map((part, index) => {
			if (part.startsWith('*') && part.endsWith('*')) {
				return <strong key={index}>{part.slice(1, -1)}</strong>;
			}
			return part;
		});
	};

	const groupMessagesByDate = ( messages ) => {
		const groups = {};
		messages.forEach( message => {
			const date = new Date( message.createdAt ).toDateString();
			if ( !groups[date] ) {
				groups[date] = [];
			}
			groups[date].push( message );
		} );
		return groups;
	};

	const handleOpenAssignDialog = () => {
		setOpenDialog( ( prev ) => !prev );
	};


	const handleLoadMessages = async () => {
		if ( !chatRef.current ) return;
		setIsLoading( true );

		const chatDiv = chatRef.current;
		const previousScrollHeight = chatDiv.scrollHeight;
		const previousScrollTop = chatDiv.scrollTop;

		await fetchMessages();

		setIsLoading( false );

		chatDiv.scrollTop = chatDiv.scrollHeight - previousScrollHeight + previousScrollTop;
	};

	const handleSendMessageWithScroll = async (e, useForm, userEmail) => {
		await handleSendMessage(e, useForm, userEmail);
		setShouldScrollToBottom(true);
	};

	const handleAssignChat = async () => {
		await assignChat( selectedChat?.id, userId, userEmail, chatIdInput );
		handleOpenAssignDialog();
	};

	const handleCloseService = async () => {
		setLoadingCloseService( true );
		await closeChat( isCaseAssigned, selectedChat?.id );
		setLoadingCloseService( false );
	};

	useEffect( () => {
		if (chatRef.current && (shouldScrollToBottom || isInitialLoad.current)) {
			chatRef.current.scrollTop = chatRef.current.scrollHeight;
			setShouldScrollToBottom(false);
			isInitialLoad.current = false;
		}
	}, [messages, shouldScrollToBottom]);

	return (
		<div style={{
			width         : '70%',
			display       : 'flex',
			flexDirection : 'column',
			height        : '100%'
		}}>
			<ChatHeader>
				<ChatHeaderInfo>
					{selectedChat ? (
						<>
							{selectedChat.profilePicUrl ? (
								<img
									src={selectedChat.profilePicUrl}
									alt="Contact"
									width={32}
									height={32}
									className="w-full h-full rounded-circle"
								/>
							) : (
								<AccountCircle className="w-16 h-16 text-zinc-400" />
							)}
							<div className="flex flex-col gap-2">
								<h3 className="text-white">{selectedChat.name}</h3>
								<p className="text-gray-300">{selectedChat.number}</p>

								<div className="flex flex-row gap-2">
									<p className="d-flex flex-row align-items-center text-gray-300 text-sm">Atendido por:
										<ButtonSelectOperator className='ml-2' onClick={handleOpenModal}>{selectedChat?.userEmail ?? 'Nenhum responsável'} <ArrowDropDown /></ButtonSelectOperator>
									</p>
								</div>
							</div>
						</>
					) : (
						<div className="flex flex-col gap-2">
							<h3 className="text-white">Não atribuído</h3>
						</div>
					)}
				</ChatHeaderInfo>
				<CloseButton onClick={closeWhatsappChat}>×</CloseButton>
			</ChatHeader>
			<ChatActions
				loadingCloseService={loadingCloseService}
				isCaseAssigned={isCaseAssigned}
				isConversationEnded={isConversationEnded}
				onEndConversation={handleCloseService}
				onAssignCase={handleOpenAssignDialog}
				caseId={selectedChat?.caseId}
			/>

			<DialogComponent
				confirmation={openDialog}
				title={'Insira o id do caso'}
				subText={'Digite um id de pelo menos 6 dígitos para vincular ao chat.'}
				closeFunction={handleOpenAssignDialog}
				callback={handleAssignChat}
			>
				<input className="h-10 w-full rounded-md border border-gray-200 bg-white px-3 py-2 transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-400" placeholder={'Digite o id do caso aqui'} value={chatIdInput} onChange={( e ) => setChatIdInput( e.target.value )}/>
			</DialogComponent>

			<ChatMessages ref={chatRef}>
				{Object.entries( groupMessagesByDate( messages ) ).map( ( [ date, dateMessages ] ) => (
					<React.Fragment key={date}>
						{!isLoading && messagesCursor && (
							<LoadMoreButton type='button' onClick={handleLoadMessages}>
								Carregar Mais
							</LoadMoreButton>
						)}
						{isLoading && (
							<LoadingMessageContainer>
								<MessageTimestamp>Carregando mensagens...</MessageTimestamp>
							</LoadingMessageContainer>
						)}
						<DateSeparator>
							{formatDate( dateMessages[0].createdAt )}
						</DateSeparator>
						{dateMessages.map( ( message ) => (
							<Message key={message.id} sender={message.type}>
								<MessageContent sender={message.type}>
									<p style={{ whiteSpace: 'pre-wrap' }}>{formatMessageContent(message.content)}</p>
									<MessageTimestamp>
										{new Date(message.createdAt).toLocaleTimeString([], {
											hour: '2-digit',
											minute: '2-digit',
                      hour12   : false,
											timeZone: 'UTC'
										})}
									</MessageTimestamp>
								</MessageContent>
							</Message>
						) )}
					</React.Fragment>
				) )}
			</ChatMessages>

			<ChatInputForm onSubmit={useForm.handleSubmit(e => handleSendMessageWithScroll(e, useForm, selectedChat.userEmail))}>
				<ChatInput
					{...useForm.register( 'message' )}
					disabled={selectedChat.userId !== userId}
					type="text"
					placeholder="Escreva sua mensagem..."
				/>
				<SendButton type="submit" disabled={selectedChat.userId !== userId}>
					<Send fontSize='small' className="text-white" />
				</SendButton>
			</ChatInputForm>

			{/* Modal para selecionar o responsável */}
			<Modal  visible={visible} onClose={handleCloseModal}>
				<div style={{ width: '500px', minWidth: '90%' }}>
					<h6>Selecione o responsável</h6>
					<SelectSearch
						variant={'outlined'}
						fullWidth
						size='small'
						disabled={selectedChat.userId !== userId && !isAdmin}
						options={operators?.map( operator => ( { label: `${operator.name} - ${operator.email}`, value: operator.id } ) ) ?? []}
						selectedState={selectedChat.userId}
						setSelectedState={( id ) => {
							handleUpdateOperator( selectedChat.id, id, operators.find( operator => operator.id === id ).email );
						}}
						input={<BootstrapInput />}
					/>
				</div>
			</Modal>
		</div>
	);
};

export { ChatArea };
