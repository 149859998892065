import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { WhatsAppAuth } from '../WhatsAppAuth';
import {
	SettingsContainer,
	SettingsHeader,
	BackButton,
	SettingsTitle,
	SettingsContent,
	SettingsOption
} from './styles';

const Settings = ( { onBack, onLogout, qrCode, isAuthenticated, isLoading } ) => {
	return (
		<SettingsContainer>
			<SettingsHeader>
				<BackButton onClick={onBack}>
					<ArrowBackIcon />
				</BackButton>
				<SettingsTitle>Configurações</SettingsTitle>
			</SettingsHeader>

			<SettingsContent>
				<WhatsAppAuth
					qrCode={qrCode}
					isAuthenticated={isAuthenticated}
					isLoading={isLoading}
				/>
				{isAuthenticated && (
					<SettingsOption onClick={onLogout}>
						<ExitToAppIcon />
						<span>Logout</span>
					</SettingsOption>
				)}
			</SettingsContent>
		</SettingsContainer>
	);
};

export { Settings };
