import { caseDetails } from 'api/case-details';
import { pipego } from 'api/pipego';
import { useNotifyAlert } from 'context/NotifyAlert';
import { useMessage } from 'newComponents/ChatWhatsapp/context/MessageContext';
import { useState } from 'react';
import { getTenantId, getUserId, isAdmin as getIsAdmin } from 'util/isAdmin';

export const useChatArea = () => {
	const tenantId = getTenantId();
	const userId = getUserId();
	const isAdmin = getIsAdmin();

	const { setShowNotify } = useNotifyAlert();
	const { setSelectedChat, selectedChat } = useMessage();

	const [ visible, setVisible ] = useState( false );

	const handleOpenModal = () => {
		setVisible( true );
	};

	const handleCloseModal = () => {
		setVisible( false );
	};

	const handleFetchOperators = async () => {
		const response = await caseDetails.getUsersNegotiatorsOptions();
		if ( response?.data.length > 0 ) {
			return response.data.filter( operator => operator.tenant_id === tenantId ).sort( ( a, b ) => a?.name?.localeCompare( b?.name ) );
		}
		return [];
	};

	const handleUpdateOperator = async ( jid, operatorId, operatorEmail ) => {
		const response = await pipego.updateOperator( jid, operatorId, operatorEmail );
		if ( response.status === 200 ) {
			setShowNotify( {
				text   : 'Responsável atualizado com sucesso',
				status : 'success'
			} );
			handleCloseModal();
			setSelectedChat( prev => ( { ...prev, userEmail: response.data.userEmail, userId: response.data.userId } ) );
		} else {
			setShowNotify( {
				text   : 'Erro ao atualizar responsável',
				status : 'error'
			} );
		}
	};

	return { isAdmin, userId, visible, handleOpenModal, handleCloseModal, handleFetchOperators, handleUpdateOperator, selectedChat };
};
