import { pipegoApi } from './createApi';


/**
 * API wrapper for Pipego service endpoints
 * @namespace pipego
 */
export const pipego = {
	/**
	 * Creates a new session by authenticating with credentials
	 * @async
	 * @function session
	 * @returns {Promise<{token: string}>} JWT token response from the session endpoint
	 */
	session: async () => {
		return await pipegoApi.post( '/session', {
			'email'    : 'admin@acordofechado.com',
			'password' : '123'
		} );
	},
	/**
	 * Sends a WhatsApp message through Pipego service
	 * @async
	 * @function message
	 * @param {Object} params Message parameters
	 * @param {string} params.clientId Client unique identifier
	 * @param {string} params.number Recipient's phone number
	 * @param {string} params.message Message content
	 * @param {number} params.delay Delay in milliseconds before sending
	 * @returns {Promise<{jobId:string}>} Response containing the job identifier
	 */
	message: async( params ) => {

		return await pipegoApi.post( '/whatsapp/message', {
			clientId : params.clientId,
			number   : params.number,
			message  : params.message,
			delay    : params.delay
		} );
	},
	/**
	 * Checks WhatsApp connection status
	 * @async
	 * @function checkWhatsAppStatus
	 * @returns {Promise<boolean>} Connection status
	 */
	checkWhatsAppStatus: async () => {
		return await pipegoApi.get( '/whatsapp/info' );
	},
	/**
	 * Sends a WhatsApp message through Pipego service with authentication
	 * @async
	 * @function messageWithAuth
	 * @param {Object} params Message parameters
	 * @returns {Promise<{jobId:string}>} Response containing the job identifier
	 */
	messageWithAuth: async ( params ) => {
		const sessionResponse = await pipego.session();
		const token = sessionResponse?.data?.token;

		if ( !token ) {
			throw new Error( 'Failed to get authentication token' );
		}

		pipegoApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;

		// Verify WhatsApp connection status
		// const isConnected = await pipego.checkWhatsAppStatus();
		// if ( !isConnected ) {
		// 	throw new Error( 'WhatsApp is not connected' );
		// }

		return await pipego.message(
			{ delay    : params.delay || 0,
				clientId : params.clientId,
				number   : params.number,
				message  : params.message
			}
		);
	},
	getMessageHistory: async ( number, cursor = null ) => {
		if ( cursor ) {
			const response = await pipegoApi.get( `/chats/${number}?cursor=${cursor}` );
			return { messages: response.data.data, cursor: response.data.cursor };
		}
		const response = await pipegoApi.get( `/chats/${number}` );
		return { messages: response.data.data, cursor: response.data.cursor };
	},

	/**
	 * Sends a message using the API
	 * @async
	 * @function sendMessage
	 * @param {string} number Recipient's phone number
	 * @param {string} message Message content
	 * @param {string} operatorEmail Operator email
	 * @returns {Promise<Object>} Response data from the API
	 */
	async sendMessage( number, message ) {
		const response = await pipegoApi.post( '/messages/send', {
			jid  : number,
			message,
			type : 'number',
		} );
		return response.data;
	},

	/**
	 * Fetches all chats from the API
	 * @async
	 * @function getChats
	 * @returns {Promise<Object>} Response data from the API
	 */
	getChats: async () => {
		const response = await pipegoApi.get( '/api/chats' );
		return response.data;
	},

	/**
	 * Fetches chats for a specific operator by email
	 * @async
	 * @function getOperatorChats
	 * @param {string} email Operator email
	 * @returns {Promise<Object>} Response data from the API
	 */
	getOperatorChats: async ( cursor = null ) => {
		if ( cursor ) {
			const response = await pipegoApi.get( `/chats?cursor=${cursor}` );
			return { chatData: response.data.data, cursor: response.data.cursor };
		}
		const response = await pipegoApi.get( '/chats' );
		return { chatData: response.data.data, cursor: response.data.cursor };
	},

	getWhatsAppStatus: async () => {
		const response = await pipegoApi.get( '/sessions/status' );
		return response.data;
	},

	createSession: async () => {
		const response = await pipegoApi.post( '/sessions/add' );
		return response.data;
	},

	logoutWhatsApp: async () => {
		const response = await pipegoApi.delete( '/sessions' );
		return response.data;
	},

	updateOperator: async ( jid, operatorId, operatorEmail, caseId = undefined ) => {
		const response = await pipegoApi.put( `/chats/${jid}`, {
			negotiatorEmail : operatorEmail,
			negotiatorId    : operatorId,
			caseId,
		} );
		return response;
	},

	closeChatService: async ( jid ) => {
		const response = await pipegoApi.put( `/chats/${jid}/close` );
		return response;
	}
};
