import { socketEvents } from 'lib/socket';
import { useEffect } from 'react';

const useWebSocket = ( onReceivedMessage ) => {

	useEffect( () => {
		socketEvents.onMessage( ( message ) => {
			onReceivedMessage( message );
		} );

		// socketEvents.onChatUpdate( ( chat ) => {
		// 	console.log( 'onChatUpdate: ', chat );
		// } );
		// socketEvents.onChatUpdate2( ( chat ) => {
		// 	console.log( 'onChatUpdate2: ', chat );
		// } );

		return () => {
			socketEvents.offMessage();
		};
	}, [] );
};

export default useWebSocket;