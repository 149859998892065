import React from 'react';
import PropTypes from 'prop-types';
import ChatIcon from '@material-ui/icons/Chat';
import SettingsIcon from '@material-ui/icons/Settings';
import {
	SidebarContainer,
	SidebarIcon,
	SidebarButton,
	UnreadBadge
} from './styles';
import { FeedbackOutlined } from '@mui/icons-material';

const Sidebar = ( { activeTab, setActiveTab, totalUnread = 0, totalUnreadPendingChat = 0 } ) => {
	return (
		<SidebarContainer>
			<div>
				<SidebarButton
					title="Conversas"
					active={activeTab === 'chats'}
					onClick={() => setActiveTab( 'chats' )}
				>
					<SidebarIcon>
						<ChatIcon />
						{totalUnread > 0 && (
							<UnreadBadge>{totalUnread}</UnreadBadge>
						)}
					</SidebarIcon>
				</SidebarButton>
				<SidebarButton
					title="Conversas pendentes"
					active={activeTab === 'pending'}
					onClick={() => setActiveTab( 'pending' )}
				>
					<SidebarIcon>
						<FeedbackOutlined />
						{totalUnreadPendingChat > 0 && (
							<UnreadBadge>{totalUnreadPendingChat}</UnreadBadge>
						)}
					</SidebarIcon>
				</SidebarButton>
			</div>

			<div>
				<SidebarButton
					title="Configurações"
					active={activeTab === 'settings'}
					onClick={() => setActiveTab( 'settings' )}
				>
					<SidebarIcon>
						<SettingsIcon />
					</SidebarIcon>
				</SidebarButton>
			</div>
		</SidebarContainer>
	);
};

Sidebar.propTypes = {
	activeTab    : PropTypes.oneOf( [ 'chats', 'pending', 'settings' ] ).isRequired,
	setActiveTab : PropTypes.func.isRequired,
	totalUnread  : PropTypes.number
};

export { Sidebar };
