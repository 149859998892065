import { caseDetailsApi } from './createApi';

const caseDetails = {
	getCaseInfo: async function( payload ) {
		try {
			return await caseDetailsApi
				.post( '/get-case-info', payload )
				.then( response => response );
		} catch ( error ) {
			console.error( 'getCaseInfo.catch', error );
			if ( error.response && error.response.status === 401 ) {
				localStorage.removeItem( 'af-token' );
				localStorage.removeItem( 'user_id' );
				window.location.href = '/signin';
			}
			if ( error.response && error.response.status === 404 ) {
				window.location.href = '/app/casos';
			}
		}
	},
	updateCaseInfo: async function( payload ) {
		try {
			return await caseDetailsApi
				.post( '/update-case-info', payload )
				.then( response => response );
		} catch ( error ) {
			console.error( 'updateCaseInfo.catch', error );
			if ( error.response && error.response.status === 404 ) {
				window.location.href = '/app/casos';
				return;
			}
			throw new Error( error.response.data.message );
		}
	},
	getLawyerCases: async function( payload ) {
		try {
			return await caseDetailsApi
				.post( '/get-lawyer-cases', payload )
				.then( response => response );
		} catch ( error ) {
			console.error( 'getLawyerCases.catch', error );
		}
	},
	getTasksOptions: async function( ) {
		try {
			return await caseDetailsApi
				.post( '/get-tasks-options' )
				.then( response => response );
		} catch ( error ) {
			console.error( 'getTasksOptions.catch', error );
		}
	},
	getUsersNegotiatorsOptions: async function( ) {
		try {
			return await caseDetailsApi
				.post( '/get-users-negotiators' )
				.then( response => response );
		} catch ( error ) {
			console.error( 'getUsersNegotiatorsOptions.catch', error );
		}
	},
	updateInitial: async function( payload ) {
		return await caseDetailsApi
			.post( '/upload-initial', payload )
			.then( response => response );
	}
};

export { caseDetails };
