import styled from 'styled-components';

export const HeaderContainer = styled.div`
  padding: 16px;
`;

export const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UnreadBadge = styled.span`
  background-color: #00a884;
  color: white;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 14px;
`;

export const FilterButton = styled.button`
  background: ${props => props.active ? '#00a884' : 'transparent'};
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
`;

export const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 8px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px 12px 8px 40px;
  background-color: #fff;
  border: 1px solid #e9edef;
  border-radius: 8px;
  color: #666;
  font-size: 14px;
`;
