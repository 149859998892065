import styled from 'styled-components';

export const ChatList = styled.div`
  width: 300px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
`;
export const ChatListItem = styled.div`
display: flex;
align-items: center;
padding: 12px;
cursor: pointer;

&:hover {
  background-color: #f5f5f5;
}
`;

export const ChatListAvatar = styled.div`
width: 40px;
height: 40px;
border-radius: 50%;
background-color: #e0e0e0;
display: flex;
align-items: center;
justify-content: center;
margin-right: 12px;
font-weight: bold;
`;

export const ChatListInfo = styled.div`
flex: 1;
`;

export const ChatListName = styled.div`
font-weight: 500;
`;

export const ChatListMessage = styled.div`
color: #666;
font-size: 0.9em;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;

// Styled "Load More" button
export const LoadMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 16px;
  background-color: #128C7E;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 14px;
  
  &:hover {
    background-color: #0e6b5e;
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  &:focus {
    outline: 2px solid #25D366;
    outline-offset: 2px;
  }
`;