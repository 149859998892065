import styled from 'styled-components';

export const ChatContainer = styled.div`
  width: 80%;
  max-width: 1200px;
  height: 80vh;
  background: white;
  /* border-radius: 10px; */
  overflow: hidden;
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ChatLayout = styled.div`
  display: flex;
  height: 100%;
`;
