import { FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { TextInputError } from 'app/routes/Dialer/components/styles';
import React, { useMemo, useState } from 'react';

const SelectSearch = ( ( { options = [], value, selectedState, setSelectedState, variant, fullWidth, label, error, helperText, ...rest }, ref ) => {
	const [ searchText, setSearchText ] = useState( '' );
	const containsText = ( text, searchText ) =>
		text.toLowerCase().indexOf( searchText.toLowerCase() ) > -1;

	const displayedOptions = useMemo(
		() => options.filter( ( option ) => containsText( option.label, searchText ) ),
    [searchText, options] //eslint-disable-line
	);

	return (
		<FormControl variant={variant} fullWidth={fullWidth}>
			<InputLabel id="search-select-label">{label}</InputLabel>
			<Select
				{...rest}
				ref={ref}
				MenuProps={{ autoFocus: false }}
				labelId="search-select-label"
				id="search-select"
				value={value ?? selectedState}
				label={label}
				error={error}
				onChange={( e ) => setSelectedState( e.target.value )}
				onClose={() => setSearchText( '' )}
				// renderValue={() => selectedState}
			>
				<ListSubheader className='py-2 bg-white'>
					<TextField
						size="small"
						autoFocus
						placeholder="Buscar..."
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							)
						}}
						onChange={( e ) => setSearchText( e.target.value )}
						onKeyDown={( e ) => {
							if ( e.key !== 'Escape' ) {
								e.stopPropagation();
							}
						}}
					/>
				</ListSubheader>
				{displayedOptions.map( ( option ) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				) )}
			</Select>
			{ error && <TextInputError>{helperText}</TextInputError> }
		</FormControl>
	);
} );

SelectSearch.displayName = 'SelectSearch';

export default React.forwardRef( SelectSearch );
