import axios from 'axios';

const pipegoAPi = axios.create( {
	baseURL: 'https://bailey-api-dev-263680973121.us-central1.run.app'
	// baseURL: 'http://localhost:3001'
	// baseURL: 'https://bailey-api-263680973121.us-central1.run.app'
} );
pipegoAPi.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );


export default pipegoAPi;
