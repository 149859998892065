import { CancelOutlined, PersonAdd } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

// Container for the action buttons
const ActionContainer = styled.div`
  display: flex;
  background-color: white;
  border-bottom: 1px solid #f3f4f6;
`;

// Divider between buttons
const Divider = styled.div`
  width: 1px;
  background-color: #e5e7eb;
`;

// Base button styles
const ActionButton = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: none;
  background-color: ${( props ) => ( props.$active ? ( props.$danger ? '#FEF2F2' : '#F0FDF4' ) : 'transparent' )};
  color: ${( props ) => ( props.$danger ? '#DC2626' : props.$active ? '#047857' : '#059669' )};
  font-size: 0.75rem;
  font-weight: 400;
  cursor: ${( props ) => ( props.disabled ? 'default' : 'pointer' )};
  opacity: ${( props ) => ( props.disabled ? 0.6 : 1 )};
  transition: background-color 0.2s;
  border-radius: 0;
  
  &:hover:not(:disabled) {
    background-color: ${( props ) => ( props.$danger ? '#FEF2F2' : '#F0FDF4' )};
  }
  
  &:focus {
    outline: none;
  }
`;

// Icon wrapper for consistent spacing
const IconWrapper = styled.span`
  display: inline-flex;
  margin-right: 0.375rem;
`;


export function ChatActions( {
	loadingCloseService,
	isCaseAssigned,
	isConversationEnded,
	onAssignCase,
	onEndConversation,
	caseId,
} ) {
	return (
		<ActionContainer>
			<ActionButton $active={isCaseAssigned} disabled={isCaseAssigned} onClick={onAssignCase}>
				{isCaseAssigned ? `Caso: ${caseId}`: <>
					<IconWrapper>
						<PersonAdd size={14} />
					</IconWrapper>
        Atribuir Caso</>}
			</ActionButton>

			<Divider />

			<ActionButton $danger disabled={isConversationEnded || loadingCloseService} onClick={onEndConversation}>
				<IconWrapper>
					<CancelOutlined size={14} />
				</IconWrapper>
				{loadingCloseService ? 'Finalizando...' : 'Finalizar'}
			</ActionButton>
		</ActionContainer>
	);
}

