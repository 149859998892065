import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AccountCircle } from '@material-ui/icons';
import { formatMessageTime } from '../../utils/dateFormatters';
import { ChatListHeader } from '../ChatListHeader';
import {
	ChatList as ChatListContainer,
	ChatListItem,
	ChatListAvatar,
	ChatListInfo,
	ChatListName,
	ChatListMessage,
	LoadMoreButton
} from './styles';
import { useMessage } from 'newComponents/ChatWhatsapp/context/MessageContext';
import { LoadingMessageContainer, MessageTimestamp } from '../ChatArea/styles';

const ChatList = ( {
	conversations,
	searchQuery,
	showUnreadOnly,
	setShowUnreadOnly,
	setSearchQuery,
	handleChatClick
} ) => {
	const { loadChats, chatListCursor } = useMessage();
	const chatListRef = useRef( null );
	const [ isLoading, setIsLoading ] = useState( false );
	const filteredConversations = conversations?.filter( conversation => {
		const contactName = conversation?.name;
		const contactPhoneNumber = conversation?.id;
		const matchesSearch = ( contactName || contactPhoneNumber )
			.toLowerCase()
			.includes( searchQuery.toLowerCase() );

		if ( showUnreadOnly ) {
			return matchesSearch && conversation?.unreadCount > 0;
		}
		return matchesSearch;
	} );

	const handleLoadChatList = async () => {
		if ( !chatListRef.current ) return;
		setIsLoading( true );

		const chatDiv = chatListRef.current;

		await loadChats();

		setIsLoading( false );

		const isAtBottom =
      chatDiv.scrollHeight - chatDiv.clientHeight <= chatDiv.scrollTop + 10;

		if ( isAtBottom ) {
			chatDiv.scrollTop = chatDiv.scrollHeight;
		}
	};

	return (
		<ChatListContainer ref={chatListRef}>
			<ChatListHeader
				showUnreadOnly={showUnreadOnly}
				setShowUnreadOnly={setShowUnreadOnly}
				searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
			/>

			{filteredConversations?.map( ( conversation ) => (
				<ChatListItem
					key={conversation.id}
					onClick={() => handleChatClick( conversation )}
					style={{ cursor: 'pointer' }}
				>
					<ChatListAvatar>
						{conversation?.contact?.profilePicUrl ? (
							<img
								width={48}
								height={48}
								src={conversation?.profilePicUrl}
								alt={conversation?.name || conversation?.id}
								style={{
									borderRadius : '50%',
									objectFit    : 'cover'
								}}
							/>
						) : (
							<AccountCircle style={{ width: 48, height: 48, color: '#666' }} />
						)}
					</ChatListAvatar>
					<ChatListInfo>
						<div style={{
							display        : 'flex',
							justifyContent : 'space-between',
							width          : '100%',
							marginBottom   : '4px'
						}}>
							<ChatListName>
								{conversation?.name || conversation?.id.split( '@' )[0]}
							</ChatListName>
							<span style={{
								fontSize : '12px',
								color    : '#00a884'
							}}>
								{formatMessageTime( conversation?.createdAt )}
							</span>
						</div>
						<div style={{
							display        : 'flex',
							justifyContent : 'space-between',
							width          : '100%'
						}}>
							<ChatListMessage>
								{conversation?.content?.length > 30
									? `${conversation?.content?.substring( 0, 30 )}...`
									: conversation?.content}
							</ChatListMessage>
							{conversation?.unreadCount > 0 && (
								<span style={{
									backgroundColor : '#00a884',
									color           : 'white',
									borderRadius    : '12px',
									padding         : '1px 6px',
									fontSize        : '12px',
									textAlign       : 'center'
								}}>
									{conversation?.unreadCount}
								</span>
							)}
						</div>
					</ChatListInfo>
				</ChatListItem>
			) )}
			{!isLoading && chatListCursor && (
				<LoadMoreButton type='button' onClick={handleLoadChatList}>
					Carregar Mais
				</LoadMoreButton>
			)}
			{isLoading && (
				<LoadingMessageContainer>
					<MessageTimestamp>Carregando mensagens...</MessageTimestamp>
				</LoadingMessageContainer>
			)}
		</ChatListContainer>
	);
};

ChatList.propTypes = {
	conversations: PropTypes.arrayOf(
		PropTypes.shape( {
			id          : PropTypes.string.isRequired,
			name        : PropTypes.string,
			content     : PropTypes.string,
			createdAt   : PropTypes.oneOfType( [ PropTypes.string, PropTypes.number, PropTypes.instanceOf( Date ) ] ),
			unreadCount : PropTypes.number,
			contact     : PropTypes.shape( {
				profilePicUrl: PropTypes.string
			} ),
			profilePicUrl: PropTypes.string
		} )
	),
	searchQuery       : PropTypes.string.isRequired,
	showUnreadOnly    : PropTypes.bool.isRequired,
	setShowUnreadOnly : PropTypes.func.isRequired,
	setSearchQuery    : PropTypes.func.isRequired,
	handleChatClick   : PropTypes.func.isRequired
};

export { ChatList };
