import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaWhatsapp } from 'react-icons/fa';
import { useWhatsappChatStore } from 'store/whatsappChat/useWhatsappChatStore';
import { socketEvents } from 'lib/socket';
import { useMessage } from 'newComponents/ChatWhatsapp/context/MessageContext';

const FloatingButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #25D366;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
  z-index: 9999;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    color: white;
    font-size: 30px;
  }
`;

const UnreadBadge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff4444;
  color: white;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  padding: 0 4px;
`;

const FloatingWhatsappButton = () => {
	const { loadChats, conversations } = useMessage();
	const { openWhatsappChat } = useWhatsappChatStore( ( state ) => state.actions );
	const [ unreadCount, setUnreadCount ] = useState( 0 );

	useEffect( () => {
		let isSubscribed = true;

		const loadUnreadCount = async () => {
			try {
				await loadChats();

				if ( isSubscribed ) {
					const total = conversations?.filter( chat => chat?.userEmail === 'lucas.leite@acordofechado.com.br' || !chat?.userEmail ).reduce( ( sum, chat ) => sum + ( chat?.unreadCount || 0 ), 0 );
					setUnreadCount( total );
				}
			} catch ( error ) {
				console.error( 'Error loading unread count:', error );
			}
		};

		const messageHandler = ( message ) => {
			if ( !message.fromMe && !message.read ) {
				setUnreadCount( prev => prev + 1 );
			}
		};

		const unreadHandler = ( data ) => {
			if ( isSubscribed ) {
				loadUnreadCount();
			}
		};

		// Initial load
		loadUnreadCount();

		// Socket event listeners
		socketEvents.onMessage( messageHandler );
		socketEvents.onUnreadUpdate( unreadHandler );

		return () => {
			isSubscribed = false;
			socketEvents.offMessage( messageHandler );
			socketEvents.offUnreadUpdate( unreadHandler );
		};
	}, [] );

	return (
		<FloatingButton onClick={() => openWhatsappChat()}>
			<FaWhatsapp />
			{unreadCount > 0 && <UnreadBadge>{unreadCount}</UnreadBadge>}
		</FloatingButton>
	);
};

export default FloatingWhatsappButton;
