import React from 'react';
import {
	AuthContainer,
	QRCodeContainer,
	StatusMessage
} from './styles';

const WhatsAppAuth = ( { qrCode, isAuthenticated, isLoading } ) => {

	return (
		<AuthContainer>
			{isAuthenticated ? (
				<StatusMessage>WhatsApp Conectado!</StatusMessage>
			) : qrCode ? (
				<QRCodeContainer>
					<StatusMessage>Escaneie este QR code com WhatsApp:</StatusMessage>
					<img
						src={qrCode}
						alt="QR Code"
						style={{
							width   : '256px',
							height  : '256px',
							display : 'block',
							margin  : 'auto'
						}}
					/>
				</QRCodeContainer>
			) : (
				<StatusMessage>
					{isLoading ? 'Carregando...' : 'Aguardando QR Code...'}
				</StatusMessage>
			)}
		</AuthContainer>
	);
};

export { WhatsAppAuth };
