import styled from 'styled-components';
export const ChatHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #075e54;
  color: white;
`;

export const ChatHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  h3 {
    margin: 0;
    font-size: 16px;
  }

  p {
    margin: 0;
    font-size: 12px;
    opacity: 0.8;
  }
`;

export const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background: #e5ddd5;
`;

export const Message = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: ${props => props.sender === 'sent' ? 'flex-end' : 'flex-start'};
`;

export const MessageContent = styled.div`
  max-width: 60%;
  padding: 8px 12px;
  border-radius: 8px;
  position: relative;
  background-color: ${props => props.sender === 'sent' ? '#dcf8c6' : 'white'};

  p {
    margin: 0;
    word-wrap: break-word;
  }
`;

export const LoadMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #128C7E;
  border: none;
  border-radius: 24px;
  padding: 8px 16px;
  margin: 12px auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: ${props => props.isLoading ? 'default' : 'pointer'};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  width: auto;
  min-width: 120px;
  opacity: ${props => props.isLoading ? 0.7 : 1};

  &:hover {
    background-color: ${props => props.isLoading ? '#f0f2f5' : '#e8e8e8'};
  }

  &:active {
    background-color: ${props => props.isLoading ? '#f0f2f5' : '#dcddde'};
    transform: ${props => props.isLoading ? 'none' : 'scale(0.98)'};
  }

  @media (max-width: 480px) {
    font-size: 13px;
    padding: 6px 12px;
  }
`;

export const LoadingMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  margin: 12px auto;
`;

export const MessageTimestamp = styled.span`
  font-size: 11px;
  color: #667781;
  float: right;
  margin-left: 8px;
  margin-top: 4px;
`;

export const ChatInputForm = styled.form`
  display: flex;
  padding: 16px;
  background-color: #f0f2f5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const ChatInput = styled.textarea`
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 24px;
  margin-right: 8px;
  outline: none;
`;

export const SendButton = styled.button`
  padding: 8px 8px;
  height: fit-content;
  width: fit-content;
  background-color: #075e54;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #128c7e;
  }
`;
export const DateSeparator = styled.div`
text-align: center;
padding: 10px 0;
margin: 10px 0;

&::before {
    content: '';
    display: inline-block;
    background-color: #e2e2e2;
    height: 1px;
    width: 100%;
    position: relative;
    vertical-align: middle;
}

span {
    background-color: #e2e2e2;
    color: #666;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 12px;
    margin: 0 10px;
}
`;
export const CloseButton = styled.button`
background: none;
border: none;
font-size: 24px;
cursor: pointer;
color: #333;
padding: 0 10px;

&:hover {
    color: #666;
}
`;

export const ButtonSelectOperator = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;