import React from 'react';
import * as S from './styles';

export function DatePicker( { value, onChange, label, ...rest } ) {

	return (
		<S.Container {...rest}>
			{label && <label>{label}</label>}
			<S.StyledDatePicker
				{...rest}
				value={value}
				onChange={onChange}
				calendarIcon={false}
			/>
		</S.Container>
	);
}
